import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/navbar";
import AboutUs from "./components/AboutUs";
import Gallery from "./components/Gallery";
import CallNow from "./components/CallNow";
import GetOffer from "./components/GetOffer";
import Services from "./components/Services";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import ImageSlider from "./components/ImageSlider";

const App = () => {
  return (
    <div className="w-full flex-col justify-center h-full">
      <Navbar />
      <ImageSlider />
      <Router>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<AboutUs />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Contact" element={<CallNow />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Impressum" element={<Impressum />} />
          <Route path="/Datenschutz" element={<Datenschutz />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
