const Datenschutz = () => {
  return (
    <div className="dataprotection-paragraph-spacing dataprotection-paragraph">
      <div className="w-full">
        <h1>Datenschutzerklärung</h1>
        <p>
          Diese Datenschutzerklärung klärt Sie über Art, Umfang, Nutzung und
          Verarbeitung personenbezogener Daten innerhalb dieser Website und
          allen verbundenen Funktionen auf <br />
        </p>
        <h1>Verantwortlicher</h1>
        <p> • Thomas Robert</p>
        <p> • thomasrobert64@web.de</p>
        <p> • 01753422709</p>
        <p>
          • <a href="/Impressum">Impressum</a>
        </p>
        <h1>Art der verarbeiteten Daten</h1>
        <p>
          • Personenbezogene Daten (z.B. Name, Email Adresse und Telefonnummer)
        </p>
        <p> • Inhaltsdaten (z.B. Angebotsanfragen)</p>
        <h1>Zweck der Verarbeitung</h1>
        <p> • Beantwortung von Kundenanfragen und Kommuikation</p>
        <h1>Betroffene Personen</h1>
        <p> • Absender des Formulars zu einem Angebot</p>
      </div>
    </div>
  );
};

export default Datenschutz;
