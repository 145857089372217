const Footer = () => {
  return (
    <div className="bg-slate-500 flex justify-center text-white">
      <div className="w-4/6 mt-8">
        <div className="items-center flex h-16">
          <p className="text-2xl">Kontaktieren Sie uns</p>
        </div>
        <div className="mb-10 mt-7">
          <p>Gebäudereinigung Thomas Robert</p>
          <p>49809 Lingen</p>
          <p>01753422709</p>
        </div>

        <hr className="border-slate-600"></hr>

        <div className="flex items-center justify-start h-8 mt-2">
          <a href="/Contact">
            <button className="cursor-pointer btn-tertiary-light">
              Kontakt
            </button>
          </a>
          <a href="/Impressum">
            <button className="ml-7 lg:ml-10 cursor-pointer btn-tertiary-light">
              Impressum
            </button>
          </a>
          <a href="/Datenschutz">
            <button className="ml-7 lg:ml-10 cursor-pointer btn-tertiary-light">
              Datenschutz
            </button>
          </a>
        </div>
        <a
          href="https://www.flaticon.com/free-icons/clean"
          className="text-[0.4rem]"
          title="clean icons"
        >
          Clean icons created by Freepik - Flaticon
        </a>
      </div>
    </div>
  );
};

export default Footer;
