const Impressum = () => {
  return (
    <div className="mb-52 mt-40 ">
      <div className="text-center">
        <h1 className="mb-1 font-semibold my-4 text-xl text-gray-900 text-lg">
          Gebäudereinigung Thomas Robert
        </h1>
        <p className="mt-6">Thomas Robert</p>
        <p>Östlicher Stadtflur 51</p>
        <p>49809 Lingen</p>
        <p className="mt-6">Tel.: 01753422709</p>
        <p>Email: thomasrobert64@web.de</p>
        <p></p>
      </div>
    </div>
  );
};

export default Impressum;
