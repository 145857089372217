import placeholderImage from "../images/gray.png";
import css from "../index.css";

const CallNow = () => {
  return (
    <div className="flex-col justify-center">
      <div className="h-full w-full mx-auto my-20 rounded-xl px-10 pb-24 pt-1">
        <div>
          <p className="text-black font-light text-4xl text-center mb-10">
            Kontakt zu uns
          </p>
          <div className="mb-4 flex justify-center">
            <p className="text-center text-md lg:text-lg font-thin mb-6">
              Haben Sie Fragen zu unserem Angebot? <br /> <br />
              Wir Beraten Sie gerne und beantworten Ihre Fragen. <br />
              Schicken Sie uns einfach eine Email, wir setzen uns
              schnellstmöglich mit Ihnen in Verbindung. <br />
              Wir sind auch telefonisch für Sie erreichbar, rüfen Sie gerne
              während den angegebenen Zeiten an.
            </p>
          </div>
          <div className="w-full flex justify-center">
            <hr className="w-12"></hr>
          </div>
          <p className="text-center text-lg font-thin mb-24 mt-6">
            Wir freuen uns auf Sie!
          </p>
          <div className="contact-wrapper">
            <div className="text-center contact-call-wrapper">
              <p className="text-xl font-semibold mb-24">
                Rufen Sie uns direkt an!
              </p>
              <div>
                <p className="mb-3 text-lg">Thomas Robert</p>
                <p className="mb-1 text-lg">01753422709</p>

                <hr className="w-12 m-auto mt-6 "></hr>
              </div>
              <p className="font-semibold mt-8">Öffnungszeiten</p>
              <div className="flex mt-6 justify-center">
                <p>Mo - Fr</p>
                <p className="ml-8">08:00 - 20:00 Uhr</p>
              </div>
              <div className="flex mt-1 justify-center">
                <p>Sa & So</p>
                <p className="ml-8">Geschlossen</p>
              </div>
            </div>
            <div className="contact-form-wrapper text-center">
              <div className="flex justify-center">
                <button
                  className=" bg-slate-500 text-white py-3 px-4 rounded-md  btn-primary"
                  onClick={() => {
                    window.open("mailto:thomasroebrt64@web.de");
                  }}
                >
                  Schreiben Sie uns eine Email!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallNow;
