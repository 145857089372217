import React from "react";
import iconClose from "../images/schliessen.png";

const GetOfferModal = ({ visible, close }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center ">
      <div className="w-11/12 lg:w-1/3 h-fit pb-8 text-center bg-white rounded-lg shadow-2xl">
        <div className="w-full flex justify-end">
          <img
            className="mr-2 mt-2 cursor-pointer"
            src={iconClose}
            onClick={close}
            height={"35px"}
            width={"35px"}
          ></img>
        </div>
        <div className="mx-12">
          <p className="mx-auto mb-5 font-semibold text-xl">
            Wir freuen uns auf Ihre Nachricht!
          </p>
          <p>
            Schicken Sie uns gerne eine Email, wir setzten uns mit Ihnen
            schnellstmöglich in Verbindung!
          </p>
          <p className="mt-4">
            Bitte nennen Sie in der Email Ihr Anliegen, die Häufigkeit der
            Dienstleistung und natürlich alle Fragen, die Sie an uns haben!
          </p>
        </div>
        <div className="flex justify-center mt-20">
          <button
            className="py-3 px-4 rounded-md font-semibold btn-primary"
            onClick={() => {
              window.open("mailto:thomasroebrt64@web.de");
            }}
          >
            Email verfassen
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetOfferModal;
