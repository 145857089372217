import placeholderImage from "../images/gray.png";

const Gallery = () => {
  return (
    <div className="my-8">
      <div className="mx-32">
        <div className="w-full flex mt-14 p-1">
          <img
            src={placeholderImage}
            className="h-96 w-3/5 mr-4 rounded-md "
          ></img>
          <img src={placeholderImage} className="w-2/5 h-96 rounded-md"></img>
        </div>
        <div className="w-full flex mt-2 p-1">
          <img
            src={placeholderImage}
            className="h-96 w-2/5 mr-4 rounded-md"
          ></img>
          <img src={placeholderImage} className="h-96 w-3/5 rounded-md"></img>
        </div>
        <div className="w-full flex mt-2 p-1">
          <img
            src={placeholderImage}
            className="h-96 w-3/5 mr-4 rounded-md"
          ></img>
          <img src={placeholderImage} className="h-96 w-2/5 rounded-md"></img>
        </div>
        <div className="w-full flex mt-2 p-1">
          <img
            src={placeholderImage}
            className="h-96 w-2/5 mr-4 rounded-md"
          ></img>
          <img src={placeholderImage} className="h-96 w-3/5 rounded-md"></img>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
