import placeholerImage from "../images/gray.png";
import image from "../images/AdobeStock_100429299.jpeg";
const ImageSlider = () => {
  return (
    <div className="ImageSlider-wrapper">
      <div
        className="w-full h-72 rounded-md shadow-sm bg-no-repeat bg-center bg-cover"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
    </div>
  );
};

export default ImageSlider;
