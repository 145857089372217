import "../Navbar.css";
import offer from "../images/offene-hand.png";
import phone from "../images/anruf.png";
import info from "../images/information.png";
import { useState } from "react";
import { Router, Routes, Route, Link, Navigate } from "react-router-dom";
import GetOfferModal from "./GetOfferModal";
import icon_burger from "../images/burger.png";

const Navbar = () => {
  const navBarElements = [
    //{ id: 1, name:"Gallerie" },
    { id: 2, name: "Angebot einholen", icon: offer, side: "/GetOffer" },
    { id: 3, name: "Jetzt anrufen", icon: phone, side: "/CallNow" },
    //{ id: 4, name:"Dienstleistungen" },
    { id: 5, name: "Über uns", icon: info, side: "/AboutUs" },
  ];

  const [showGetOfferModal, setshowGetOfferModal] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  const handleClose = () => setshowGetOfferModal(false);
  const handleCLickAngebot = () => {
    setshowGetOfferModal(!showGetOfferModal);
  };

  return (
    <>
      <div className="bg-gray-50 rounded-md shadow-md mt-0 navbar-wrapper z-50 flex w-full justify-between items-center sticky top-0">
        <a href="/Home">
          <div className="navbar-logo-wrapper">
            <div className="cursor-pointer">
              <h1 className="font-semibold text-xl">
                Gebäudereinugung{" "}
                <span className="logo-inh-wrapper">inh. Thomas Robert</span>
              </h1>
              <hr className="m-1 border-blue-600" />
              <hr className="border-blue-600" />
            </div>
          </div>
        </a>

        <div
          className={sidebar ? "menu-button-open" : "menu-button"}
          onClick={() => {
            showSidebar();
          }}
        >
          <div className="menu-button-burger"></div>
        </div>

        {/*<div className="burger-menu-icon">
          <img
            src={icon_burger}
            className="h-8 w-8 cursor-pointer"
            onClick={showSidebar}
          ></img>
        </div>*/}
        <div className="wrapper">
          <div className="mr-12">
            <div
              className="flex cursor-pointer"
              onClick={() => {
                handleCLickAngebot();
              }}
            >
              <button className="btn-tertiary">Angebot einholen</button>
            </div>
          </div>
          <a href="/Contact">
            <div className="mr-12 cursor-pointer">
              <div className="flex">
                <button className="btn-tertiary">Kontakt</button>
              </div>
            </div>
          </a>
        </div>
        <GetOfferModal close={handleClose} visible={showGetOfferModal} />
      </div>

      <div className={` ${sidebar ? "nav-menu active" : "nav-menu"} relative`}>
        <ul className="nav-menu-items" onClick={showSidebar}>
          <li>
            <div
              className="ml-6 mt-32 text-slate-700 py-2"
              onClick={() => {
                handleCLickAngebot();
              }}
            >
              <p>Angebot einholen</p>
            </div>
          </li>
          <li>
            <a href="/Contact">
              <div className="ml-6 mt-3 text-slate-700 py-2">
                <p>Kontakt</p>
              </div>
            </a>
          </li>
          <hr className="mr-4 ml-4 mt-5"></hr>
          <li className="mt-2">
            <a href="/Impressum">
              <div className="ml-6 text-slate-700 py-2">
                <p>Impressum</p>
              </div>
            </a>
          </li>
          <li>
            <a href="/Datenschutz">
              <div className="ml-6 text-slate-700 py-2">
                <p>Datenschutz</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
