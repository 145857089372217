import placeholderImage from "../images/gray.png";
import arrowup from "../images/arrowup.png";
import arrowdown from "../images/arrowdown.png";
import { useEffect, useState } from "react";
import stairs from "../images/AdobeStock_464089041.jpeg";
import window from "../images/AdobeStock_224549071.jpeg";

import { useInView } from "react-intersection-observer";

const Home = () => {
  const { ref: RefGebaeudereinigung, inView: inViewGebaeudereinigung } =
    useInView();
  const { ref: RefGlasreinigung, inView: inViewGlasreinigung } = useInView();

  return (
    <div className="flex-col justify-center my-4 m-2">
      <div className="flex-col justify-center mb-24 mt-4">
        <div className="w-full flex justify-start">
          <p className="mt-16 service-title-wrapper font-medium text-4xl">
            Unsere Angebote
          </p>
        </div>

        <div className="flex mt-14">
          <div
            className={`service-wrapper defaulthidden ${
              inViewGebaeudereinigung ? " defaultshow " : ""
            } `}
            ref={RefGebaeudereinigung}
          >
            <img
              className="w-80 h-56 object-cover rounded-md m-auto shadow-md"
              src={stairs}
            ></img>
            <div className="service-text-wrapper">
              <p className="font-semibold text-xl lg:text-2xl">
                Treppenhausreinigung
              </p>
              <hr className="m-3 w-full"></hr>
              <p className="mt-10 text-lg">
                Sie möchten in Ihrem Mehrfamilienhaus in ein sauberes
                Treppenhaus eintreten?
                <br />
                Wir sind Ihr Ansprechpartner für Treppenhausreinigungen und
                unterstützen Sie hier gerne!
              </p>
            </div>
          </div>
        </div>

        <div className="flex mt-14 ">
          <div
            className={`service-wrapper defaulthidden ${
              inViewGlasreinigung ? " defaultshow " : ""
            } `}
            ref={RefGlasreinigung}
          >
            <img
              className="w-80 h-56 object-cover rounded-md m-auto shadow-md"
              src={window}
            ></img>
            <div className="service-text-wrapper">
              <p className="font-semibold text-xl lg:text-2xl">Glasreinugung</p>
              <hr className="m-3 w-full"></hr>
              <p className="mt-10 text-lg">
                Für den besten Ausblick aus Ihrer Immobilie reinigen wir Ihre
                Fenster!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
